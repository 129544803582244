import React from 'react'
import { Container, Box, Typography } from '@material-ui/core'

const Footer: React.FC = () => {
    return (
        <>
            <Container maxWidth="sm">
                <Box>
                    <Typography variant="caption">test</Typography>
                </Box>
            </Container>
        </>
    )
}

export default Footer
